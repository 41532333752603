import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import FCSimpleWithWiring from "./falseCeilingSimpleCalculator"

import FalseCeilingAdvanceCalculator from "./falseceilingComplexcalculator"

import { Container, Row, Col } from "react-bootstrap"

const BudgetCalculator = () => (
  <Layout>
    <SEO
      title="Budget Calculator"
      keyword={["Budget Calculator", "False Ceiling Calculator", "Kitchen Calculator"]}
    />
    <Container>
      <center>
        <h2>Budget Calculator</h2>
      </center>
      <Row>
        <Col lg={6}>
          <center><h4><u>FALSE CEILING QUICK CALCULATOR</u></h4></center>
        <h5>Get your Quick False Ceiling Estimates</h5>
        <FCSimpleWithWiring/>
        
        </Col>
        <Col lg={6}>
        <center><h4><u>FALSE CEILING ADVANCE CALCULATOR</u></h4></center>
        <br></br>
        <h5>Get your complete False Ceiling Estimates</h5>
        <FalseCeilingAdvanceCalculator/>
        </Col>
      </Row>
      
    </Container>
  </Layout>
)

export default BudgetCalculator
